<template>
<div class="accordion-item" :class="{'active-item': isActive}">
    <h2 class="accordion-header">
      <button class="accordion-button p-2" :class="{'collapsed': !isActive}" type="button" data-bs-toggle="collapse" :data-bs-target="`#${uid}`" @click="handleCollapse">
        <slot name="header"></slot>
      </button>
    </h2>
    <div :id="uid" class="accordion-collapse collapse " :class="{'show': isActive}" :data-bs-parent="`#${parent}`">
      <div class="accordion-body p-1 w-100">
            <slot></slot>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import type DataGridControl from 'o365.controls.DataGrid.ts';
import type { Ref } from 'vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import localStorageHelper from 'o365.modules.StorageHelpers.ts';
import { inject } from 'vue';
import logger from 'o365.modules.Logger.ts';

const props = defineProps<{
    itemId: string,
    parent: string;
    active?: boolean;
}>();

const uid = `collapse_${crypto.randomUUID()}`;
const dataGridControl: Ref<DataGridControl>|null = inject(dataGridControlKey, null);
const localStorageKey = `gridMenu_${dataGridControl?.value?.id ?? 'global'}item`;
let isActive = false;
try {
  const lastOpen = localStorageHelper.getItem(localStorageKey) ?? '';
  if (!lastOpen) {
    isActive = props.active;
  } else {
    isActive = lastOpen === props.itemId;
  }
} catch {
  isActive = false;
}

function handleCollapse(e: MouseEvent) {
  window.requestAnimationFrame(() => {
      const target = e.target as HTMLElement;
      const parent = document.getElementById(props.parent);
      parent?.querySelectorAll<HTMLElement>('.accordion-item').forEach(item => item.classList.remove('active-item'));
      if (!target.classList.contains('collapsed')) {
        target.closest('.accordion-item')?.classList.add('active-item');
        try {
          localStorageHelper.setItem(localStorageKey, props.itemId);
        } catch (ex) {
          logger.error(ex);
        }
      }
  });
}
</script>

<style scoped>
  .active-item {
    flex-grow: 1;
    overflow-y: auto;
  }
/*

  .accordion-item:has(.accordion-button:not(.collapsed)) {
      flex-grow: 1;
  }
  .accordion-collapse.collapse.show {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .accordion-collapse.collapse.show .accordion-body {
    flex-grow: 1;
  }

  .accordion-item {
    display: flex;
    flex-direction: column;
  }
  */
.accordion-item {
  min-height: 37px;
}
.accordion-header {
  max-height: 36px;
  min-height: 36px;
}
.accordion-collapse.show{
    display: flex;
    height:calc(100% - 36px);
}
.accordion-body{
    overflow: auto;
    flex:0 0 auto;
}
.accordion-collapse{
    flex:0 0 auto;
}
.accordion-item{
    display: flex;
    flex-direction: column;
}
</style>